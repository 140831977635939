import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';

const ConfirmDialog = ({ open, handleClose, handleSubmit, title, text, fullWidth = false, maxWidth = 'sm',
  input = {}, inputs = [], isAlert, confirmButtomText, component, scroll = "paper", clickAwayClose = false,
  closeOnPathChange = true }) => {
  const [value, setValue] = useState('');
  const { pathname } = useLocation();

  const handleOk = e => {
    e.preventDefault();
    setValue('');
    handleSubmit((input.title || inputs.length > 0) ? value : true);
  }

  useEffect(() => {
    if (input.initialValue)
      setValue(input.initialValue);
  }, [input.initialValue]);

  useEffect(() => {
    if (inputs.length === 0)
      return;
    const state = Object.fromEntries(inputs.map(x => [x.name, x.initialValue]));
    setValue(state);
  }, [inputs]);

  const prevPath = usePrevious(pathname);
  useEffect(() => {
    // console.log("effect", closeOnPathChange, pathname, prevPath, pathname !== prevPath)
    if (closeOnPathChange && pathname && pathname !== prevPath)
      handleSubmit(true);
  }, [pathname, closeOnPathChange, handleSubmit, prevPath]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={clickAwayClose ? handleOk : handleClose}
      scroll={scroll}
      style={{ zIndex: 2000 }}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {text &&
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        }
        {component ? component : null}
        <form onSubmit={handleOk} id="form-dialog-form">
          {input.title &&
            <TextField
              autoFocus
              margin="dense"
              label={input.title}
              fullWidth
              type={input.type || "text"}
              name={input.name || "form-dialog-input"}
              multiline={input.rows > 1}
              rows={input.rows}
              variant={input.variant}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          }
          {inputs.map((input, index) => (
            <TextField
              key={input.name}
              autoFocus={index === 0}
              margin="dense"
              label={input.title}
              fullWidth
              value={value[input.name]}
              onChange={({ target }) => setValue(x => ({ ...x, [input.name]: target.value }))}
              type={input.type}
              disabled={input.disabled}
            />
          ))}
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="form-dialog-form" color="primary">
          {confirmButtomText || "Ok"}
        </Button>
        {!isAlert &&
          <Button onClick={handleClose} color="primary" autoFocus={!input.title && inputs.length === 0}>
            Cancel
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
