import React, { useMemo, useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMe from '../hooks/useMe';
import ConfirmEmail from './auth/ConfirmEmail';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { subscribe as subscribeFn, getPlans, purchase as purchaseFn, } from '../api';
import { CircularProgress, Container } from '@mui/material';
import Plans from './Plans';
import { useFeedback } from './feedback/Service';
import { useMutation,/*  useQuery, */ useQueryClient } from '@tanstack/react-query';
import LoadingButton from '@mui/lab/LoadingButton';
import Stepper from './Stepper';
import SignUpForm from './auth/SignUpForm';

const initialSteps = [
  { id: 0, label: "Registration" },
  { id: 1, label: "Confirm email" },
  { id: 2, label: "Payment" },
];

const Subscribe = () => {
  const queryPlanIndex = useMemo(() => new URLSearchParams(window.location.search).get("plan"), []);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [payment, setPayment] = React.useState(null);
  const { user, isPending } = useMe();
  const { data: plans, isPending: loadingPlans } = useQuery({
    queryKey: ['plans'],
    queryFn: getPlans,
  });
  const selectedPlan = useMemo(() => plans?.find(p => p._id === selectedPlanId), [plans, selectedPlanId]);
  const feedback = useFeedback();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate: purchase, isPending: loadingPurchase } = useMutation({
    mutationFn: purchaseFn,
    onSuccess: payment => {
      console.log("Purchase onSuccess", payment);
      setPayment(payment);
    },
    onError: error => {
      console.error("Purchase error", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });

  const { mutate: subscribe, isPending: loadingSubscribe } = useMutation({
    mutationFn: subscribeFn,
    onSuccess: () => {
      console.log("Subscribe onSuccess");
      queryClient.invalidateQueries({ queryKey: ['me'] });
      navigate("/account");
    },
    onError: error => {
      console.error("Subscribe error", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });

  const step = useMemo(() => {
    if (!user) return 0;
    let result = 0;
    if (user)
      result = 1;
    if (!user.emailConfirmed)
      return 1;
    result = 2;
    return result;
  }, [user]);

  const steps = useMemo(() => {
    return initialSteps.map((x, i) => ({ ...x, completed: i < step, disabled: i > step || step === 5 /* || (i === 5 && lastCompletedStep > 5) */ }))
  }, [step]);

  const handleSubscribe = () => {
    purchase({ planId: selectedPlanId });
  }

  useEffect(() => {
    if (queryPlanIndex && plans) {
      const plan = plans[queryPlanIndex];
      if (plan)
        setSelectedPlanId(plan._id);
    }
  }, [queryPlanIndex, plans]);

  useEffect(() => {
    if (!payment)
      return;
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: payment.paymentToken,
      customization: {
        modal: true
      },
      error_callback: function (error) {
        console.error('Error', error);
      }
    });
    checkout.on('success', () => {
      console.log('Payment success');
      subscribe({ paymentId: payment.id, planId: selectedPlanId });
      //Deletion of an initialized widget
      checkout.destroy();
    });
    checkout.render();
    return () => {
      if (checkout)
        checkout.destroy();
    }
  }, [payment, subscribe, selectedPlanId]);

  if (isPending) return null;
  // if (!user) return <Navigate to="/sign-in" />;
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      {(user?.subscriptions.length || 0) === 0 &&
        <Container maxWidth="md" sx={{ mb: 4 }}>
          <Stepper steps={steps} activeStep={step} setStep={() => { }} />
        </Container>
      }
      {step === 0 &&
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <SignUpForm />
          </Box>
        </Container>
      }
      {step === 1 &&
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ConfirmEmail />
          </Box>
        </Container>
      }
      {step === 2 &&
        <Box sx={{ m: 1 }}>
          {loadingPlans ? <CircularProgress /> : <Plans selectedPlanId={selectedPlanId} setSelectedPlanId={setSelectedPlanId} data={[...plans].sort((a, b) => a.price - b.price)} />}
          <Box sx={{ mt: 4, textAlign: "center", height: 200 }}>
            {selectedPlan &&
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={!selectedPlanId}
                size="large"
                onClick={handleSubscribe}
                loading={loadingPurchase || loadingSubscribe}
              >
                Purchase {selectedPlan.name} for {selectedPlan.currency}{selectedPlan.price.toFixed(2)}
              </LoadingButton>
            }
          </Box>
        </Box>
      }
    </Box>
  );
}

export default Subscribe;