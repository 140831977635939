import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useLoginRedirect(user) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("useLoginRedirect", user);
    if (!user)
      return;
    if (!user.emailConfirmed)
      return;
    const url = user.role === "admin" ? "/admin/users" : "/account";
    console.log("useLoginRedirect", "redirect", url);
    navigate(url);
  }, [user, navigate]);

  return null;
}

export default useLoginRedirect;