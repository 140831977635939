import React from 'react';
import Box from '@mui/material/Box';
import DataGrid from '../../../DataGrid';
// import { useFeedback } from '../../feedback/Service';
// import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { updateUser as updateUserFn } from '../../../api';
import { formatTimestamp } from '../../../utils';


const cols = [
  { accessorKey: '_id', header: 'ID', size: 100, hide: true, enableClickToCopy: true },
  { accessorFn: ({ plan }) => plan?.name, header: 'Plan', size: 150, pin: true },
  { accessorFn: ({ createdAt }) => formatTimestamp(createdAt), header: 'Created', size: 150 },
  { accessorFn: ({ updatedAt }) => formatTimestamp(updatedAt), header: 'Updated', size: 150 },
  { accessorFn: ({ expiresAt }) => formatTimestamp(expiresAt), header: 'Expires', size: 150 },
];

const SubscriptionsGrid = ({ data, handleShowDetails, sx, ...rest }) => {
  return (
    <Box sx={{ height: 'calc(100vh - 128px)', width: '100%', ...sx }}>
      <DataGrid
        data={data}
        columns={cols}
        // onRowDoubleClick={handleRowDoubleClick}
        {...rest}
      />
    </Box>
  );
}

export default SubscriptionsGrid;