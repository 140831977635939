import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import Card from '@mui/material/Card';
// import CardActionArea from '@mui/material/CardActionArea';
// import CardContent from '@mui/material/CardContent';
// import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import useMe from '../hooks/useMe';

const tiers = [
  {
    title: "Sign Up",
    subheader: "",
    description: [],
    url: "/subscribe",
    color: "primary"
  },
  // {
  //   title: "Sign In",
  //   subheader: "",
  //   description: [],
  //   url: "/sign-in",
  // }
];

const Home = () => {
  const { user } = useMe();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user)
      return navigate("/subscribe");;
    // const { me: user } = meData;
    navigate("/account");
  }, [user, navigate]);

  return (
    <Box
      sx={{
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container disableGutters maxWidth="md" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Sign Up
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ whiteSpace: "pre-wrap" }}>
          First Line {"\r\n"}Second Line
        </Typography>
        {/* </Container> */}
        {/* <Container maxWidth="md" component="main"> */}
        <br />
        {/* <br /> */}
        <Grid container spacing={0} alignItems="center" textAlign="center" sx={{ display: "block" }}>
          {tiers.map(tier => (
            <Button key={tier.title} component={Link} color={tier.color} variant="contained" to={tier.url} size="large" sx={{ margin: 2 }}>{tier.title}</Button>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Home;

/*<Grid item xs={12} sm={6} md={6} key={tier.title}>
   <Card>
    <CardActionArea component={Link} to={tier.url}>
      <CardHeader
        title={tier.title}
        subheader={tier.subheader}
        titleTypographyProps={{ align: 'center', gutterBottom: true }}
        // subheaderTypographyProps={{
        //   align: 'center',
        // }}
        // sx={{
        //   backgroundColor: (theme) =>
        //     theme.palette.mode === 'light'
        //       ? theme.palette.grey[200]
        //       : theme.palette.grey[700],
        // }}
      />
      <CardContent>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            mb: 2,
          }}
        >
          <Typography component="h2" variant="h3" color="text.primary">
            ${tier.price}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            /mo
          </Typography>
        </Box>
        <ul>
          {tier.description.map((line) => (
            <Typography
              component="li"
              variant="subtitle1"
              align="center"
              key={line}
            >
              {line}
            </Typography>
          ))}
        </ul>
      </CardContent>
    </CardActionArea>
  </Card> 
</Grid> */