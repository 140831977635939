import React from 'react';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    // Sentry.withScope((scope) => {
    //   scope.setExtras(errorInfo);
    //   const eventId = Sentry.captureException(error);
    //   this.setState({ eventId });
    // });
  }
  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <br />
          <br />
          <Typography variant="h4">Oops! something went wrong :(</Typography>
          <br />
          {/* <Button variant="contained" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button> */}
        </Container>
      );
    }
    return this.props.children || (<></>);
  }
}

export default ErrorBoundary;