import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link, /* useHistory */ } from 'react-router-dom';
import { useFeedback } from '../feedback/Service';
import { AUTH_TOKEN_NAME } from '../../constants';
import useLoginRedirect from '../../hooks/useLoginRedirect';
import Password from './Password';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { login as loginFunc, restorePassword as restoreFunc } from '../../api';
import useMe from '../../hooks/useMe';

export default function SignIn() {
  const { user } = useMe();
  const queryClient = useQueryClient();
  const { mutate: login, isPending: loading } = useMutation({
    mutationFn: loginFunc,
    onSuccess: token => {
      console.log("onSuccess", token);
      localStorage.setItem(AUTH_TOKEN_NAME, token);
      queryClient.invalidateQueries({ queryKey: ['me'] })
    },
    onError: error => {
      console.error("Sign in error", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });
  const { mutate: restorePassword } = useMutation({
    mutationFn: restoreFunc,
    onSuccess: () => {
      feedback.snackbar({ text: "Instructions sent to your email", type: "info" });
    },
    onError: error => {
      console.error("Restore password error", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  useLoginRedirect(user);
  const feedback = useFeedback();
  // const history = useHistory();

  const handleSubmit = async event => {
    event.preventDefault();
    // const formData = new FormData(event.currentTarget);
    const user = { email, password };
    login({ user });
    // try {


    //   // await client.clearStore();
    //   getUser({ fetchPolicy: 'network-only' });
    // } catch (error) {
    //   console.log(error.message);
    //   feedback.snackbar({ text: error.message || "Login error", type: "error" });
    // }
  };

  const handleForgotPassword = async e => {
    e.preventDefault();
    const restorationEmail = await feedback.form({ title: "Restore Password", input: { title: "Email Address", initialValue: email, name: "email", type: "email" } });
    if (!restorationEmail)
      return;
    restorePassword({ email: restorationEmail });
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          mt: 8,
          mb: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
          />
          <Password
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 1.5, mb: 2 }} loading={loading}>
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <MuiLink href="#" variant="body2" onClick={handleForgotPassword}>
                Forgot password?
              </MuiLink>
            </Grid>
            <Grid item>
              <MuiLink component={Link} to="/sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
              </MuiLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}