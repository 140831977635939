import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useFeedback } from '../feedback/Service';
// import ME from '../../gql/common/me.query';
import ReactCodeInput from 'react-verification-code-input';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { confirmEmail as confirmEmailFunc, resetEmailConfirmationCode as resetEmailConfirmationCodeFunc } from '../../api';

const useStyles = makeStyles(_ => ({
  root: ({ theme }) => ({
    color: "red",
    "& input": {
      fontFamily: "Roboto !important",
    },
    "& input:focus": {
      borderColor: theme.palette.primary.main + " !important",
    },
  }),
}));

// const CONFIRM_EMAIL = gql`
//   mutation confirmEmail($code: String!) {
//     confirmEmail(code: $code)
//   }
// `;

// const RESTORE_EMAIL_CONFIRMATION = gql`
//   mutation resetEmailConfirmation {
//     resetEmailConfirmation
//   }
// `;

export default function ConfirmEmail({ userId }) {
  const queryClient = useQueryClient();
  const { mutate: confirmEmail, isPending: loading } = useMutation({
    mutationFn: confirmEmailFunc,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
      feedback.snackbar({ text: "Email confirmed", type: "success" });
    },
    onError: error => {
      console.error("Confirm email error", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });

  const { mutate: resetEmailConfirmationCode } = useMutation({
    mutationFn: resetEmailConfirmationCodeFunc,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
      feedback.snackbar({ text: "New code was sent to your email", type: "info" });
    },
    onError: error => {
      console.error("Confirm email error", error);
      feedback.snackbar({ text: error.message || "Code resend error", type: "error" });
    }
  });

  const [code, setCode] = useState("");
  // const { data: user, refetch: getUser } = useQuery(ME);
  const feedback = useFeedback();
  const theme = useTheme();
  const classes = useStyles({ theme });

  const handleSubmit = async event => {
    event.preventDefault();
    confirmEmail({ code });
  };

  const handleResend = async e => {
    e.preventDefault();
    resetEmailConfirmationCode();
  }
  console.log(classes.root);

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          // mt: 8,
          // mb: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Confirm Email
        </Typography>
        <Typography>
          Confirmation code was sent to your email
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <ReactCodeInput value={code} onChange={setCode} type="number" fields={4} autoFocus className={classes.root} />
          <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 1.5, mb: 2 }} loading={loading}>
            Submit
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <MuiLink href="#" variant="body2" onClick={handleResend}>
                Send new code
              </MuiLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}