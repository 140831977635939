import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import useMe from '../hooks/useMe';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { useMutation,/*  useQuery, */ useQueryClient } from '@tanstack/react-query';
import { unsubscribe as unsubscribeFn /* , getAccessKey */ } from '../api';
import { useFeedback } from './feedback/Service';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { formatBytes, formatTimestamp } from '../utils';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
// import config from '../config';

const ItemWrapper = ({ children, accessKeyData: accessKey }) => {
  const feedback = useFeedback();
  if (!accessKey) return children;
  return (<CopyToClipboard text={accessKey.accessUrl} onCopy={() => feedback.snackbar({ text: "Access URL copied to clipboard", type: "success" })}>
    {children}
  </CopyToClipboard>);
}

const SubscriptionItem = ({ subscription }) => {
  const queryClient = useQueryClient();
  const { mutate: cancel, isPending: isCancelling } = useMutation({
    mutationFn: unsubscribeFn,
    onSuccess: () => {
      console.log("Subscribe onSuccess");
      queryClient.invalidateQueries({ queryKey: ['me'] });
      feedback.snackbar({ text: "Subscription cancelled", type: "success" });
    },
    onError: error => {
      console.error("Subscribe error", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });
  const feedback = useFeedback();

  const handleCancel = async () => {
    if (!await feedback.confirm({ title: "Cancel subscription", text: "Are you sure you want to cancel this subscription?" }))
      return;
    cancel({ id: subscription._id });
  }

  const secret = `${subscription.method}:${subscription.password}`;
  const secret24 = btoa(unescape(encodeURIComponent(secret)));
  subscription.accessUrl = subscription.accessKeyId ? `ss://${secret24}@${subscription.server}:${subscription.port}/?outline=1` : undefined;
  const title = (subscription.paymentMethod && !subscription.plan.isSingle) ? `Payment method: ${subscription.paymentMethod?.title}, Next payment: ${subscription.plan.currency}${subscription.plan.price.toFixed(2)} at ${formatTimestamp(subscription.expiresAt)}` : `${subscription.plan.isSingle ? "Expires" : "Cancelled, expires"}: ${formatTimestamp(subscription.expiresAt)}`
  const limit = subscription.limit !== -1 ? `Limit: ${formatBytes(subscription.limit)}, ` : "";
  return (
    <ListItem disablePadding>
      <ListItemButton>
        {/* {isPending ? <CircularProgress /> : null} */}
        <ListItemText
          primary={subscription.plan.name + ", " + (subscription.isSuspended ? "Expired " + formatTimestamp(subscription.expiresAt) : limit + title)}
          secondary={subscription.accessUrl}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {subscription.accessKeyId &&
          <ItemWrapper accessKeyData={subscription}>
            <IconButton><CopyIcon /></IconButton>
          </ItemWrapper>
        }
        {(subscription.paymentMethod && !subscription.isSuspended) && <IconButton color="error" onClick={handleCancel} disabled={isCancelling}><CancelIcon /></IconButton>}
      </ListItemButton>

    </ListItem >
  );
}

const Subscriptions = () => {
  const { user } = useMe();

  if (!user) return null;
  const { subscriptions = [] } = user;
  return (
    <>
      <Typography variant="h4">Subscriptions</Typography>
      <Typography variant='subtitle1' sx={{ mb: 2 }}>{user.email}</Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        component={Link}
        to="/subscribe"
      >
        Subscribe
      </Button>
      {subscriptions.length > 0 ?
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <nav aria-label="main mailbox folders">
            <List>
              {subscriptions.map((subscription, index) => (
                <Fragment key={index}>
                  {index > 0 && <Divider />}
                  <SubscriptionItem subscription={subscription} />
                </Fragment>
              ))}
            </List>
          </nav>
        </Box>
        :
        <>
          <Typography variant="body1" gutterBottom>You have no subscriptions.</Typography>
        </>
      }
    </>
  );
}

export default Subscriptions;