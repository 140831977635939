import config from './config';
import { AUTH_TOKEN_NAME } from './constants';
import axios from "axios";
const API_HOST = config.apiUrl;


export const getMe = () => {
  console.log("getMe");
  if (!localStorage.getItem(AUTH_TOKEN_NAME))
    throw new Error("No token found");
  return req('users/me');
}

export const getUsers = () => {
  return req('users/list');
}

export const updateUser = ({ id, ...data }) => {
  console.log("updateUser", id, data);
  return req(`users/update/${id}`, 'put', data);
}

export const login = (data) => {
  return req('users/login', 'post', data);
}

export const getPlans = () => {
  return req('plans/list-available');
}

export const register = (data) => {
  return req('users/register', 'post', data);
}

export const restorePassword = (data) => {
  return req('users/restore-password', 'post', data);
}

export const resetPassword = (data) => {
  return req('users/reset-password', 'post', data);
}

export const confirmEmail = (data) => {
  return req('users/confirm-email', 'post', data);
}

export const resetEmailConfirmationCode = () => {
  return req('users/reset-email-confirmation', 'post');
}

export const subscribe = (data) => {
  return req('users/subscribe', 'post', data);
}

export const unsubscribe = (data) => {
  return req('users/unsubscribe', 'post', data);
}

export const purchase = (data) => {
  return req('users/purchase', 'post', data);
}

export const getAccessKey = id => {
  return req('outline/getKey?id=' + id, 'get');
}

const req = (path, method = 'get', data, axiosOpts = {}) => {
  const url = `${API_HOST}/api/${path}`;

  let token = "";
  try {
    token = localStorage.getItem(AUTH_TOKEN_NAME);
  }
  catch (ex) {
    console.warn("[API]", "error reading local storage", ex);
  }

  return axios({
    url,
    method,
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...axiosOpts
  }).then(({ data }) => data).catch((err) => {
    // console.error("[API]", err);
    throw err.response.data;
  });
}