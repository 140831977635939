import React, { useMemo } from 'react';
// import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import { /* lighten,  */useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { Box, IconButton } from '@mui/material';
import OpenIcon from '@mui/icons-material/OpenInNew';

const useStyles = makeStyles(_ => ({
  root: ({ theme }) => ({
    // display: 'flex',
    // height: '100%',
    // "& > div": {
    //   flexGrow: 1,
    // },
  }),
}));

const DataGrid = ({ data, columns: cols, isPosting, onSave, onRowDoubleClick, enableEditing = false, subRow, renderDetailPanel, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const columns = useMemo(() => {
    return cols.map(col => {
      if (col.filterVariant === "multi-select" && col.filterField && data) {
        col.filterSelectOptions = [...new Set(...data.map(x => x[col.filterField || col.accessorKey]))];
      }
      if (!col.enableEditing)
        col.enableEditing = false;
      return col;
    });
  }, [cols, data]);

  // console.log("data", data, columns);

  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true, //enable a feature for all columns
    // enableGlobalFilter: false, //turn off a feature
    enableColumnPinning: true, //enable a feature for all columns,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableFacetedValues: true,
    enableEditing: enableEditing,
    enableGrouping: true,
    renderDetailPanel,
    enableExpanding: !!subRow,
    getSubRows: x => x[subRow],
    onEditingRowSave: ({ table, values }) => {
      onSave(values);
      table.setEditingRow(null); //exit editing mode
    },
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        if (onRowDoubleClick)
          onRowDoubleClick(row.original)
      },
    }),
    enableRowActions: !!onRowDoubleClick,
    renderRowActions: !onRowDoubleClick ? undefined : ({ row }) => (
      <Box>
        <IconButton onClick={() => onRowDoubleClick(row.original)}>
          <OpenIcon />
        </IconButton>
        {/* <IconButton onClick={() => console.info('Delete')}>
          <DeleteIcon />
        </IconButton> */}
      </Box>
    ),
    muiTablePaperProps: ({ table }) => ({
      elevation: 0,
      sx: {
        // width: '100%',
        // borderRadius: '0',
        // border: '1px dashed #e0e0e0',
      },
      style: {
        zIndex: table.getState().isFullScreen ? 10000 : undefined,
      },
    }),
    initialState: {
      expanded: !!renderDetailPanel,
      columnPinning: { left: columns.filter(x => x.pin).map(x => x.id) }
    },
    state: {
      isSaving: isPosting,
    },
    ...rest
  });

  return (
    <div className={classes.root}>
      {/* <div> */}
      <MaterialReactTable table={table} />
      {/* </div> */}
    </div>
  );
}

export default DataGrid;