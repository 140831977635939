import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { useFeedback } from '../feedback/Service';
import Password from './Password';
import { useMutation } from '@tanstack/react-query';
import { resetPassword as resetFunc } from '../../api';


export default function ResetPassword() {
  const { mutate: resetPassword, isPending: loading } = useMutation({
    mutationFn: resetFunc,
    onSuccess: () => {
      feedback.snackbar({ text: "Password set", type: "success" });
      navigate("/sign-in");
    },
    onError: error => {
      console.log(error.message);
      feedback.snackbar({ text: error.message || "Reset error", type: "error" });
    }
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const feedback = useFeedback();
  const navigate = useNavigate();

  const handleSubmit = async event => {
    event.preventDefault();
    resetPassword({ token: window.location.hash.substring(1), password });
  };

  useEffect(() => {
    if (!window.location.hash)
      navigate("/");
  }, [navigate]);

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Password
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            helperText="Password must be at least 6 characters long"
          />
          <Password
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            id="confirmPassword"
            autoComplete="new-password-repeat"
            value={confirmPassword}
            onChange={({ target: { value } }) => setConfirmPassword(value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 1.5, mb: 2 }} loading={loading} disabled={password !== confirmPassword}>
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}