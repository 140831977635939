import { useQuery } from "@tanstack/react-query";
import { getMe } from "../api";
import { AUTH_TOKEN_NAME } from "../constants";

function useMe() {
  const { data: user, isPending, error } = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    retry: (failureCount, error) => {
      if (error.message === "Unauthenticated") {
        localStorage.setItem(AUTH_TOKEN_NAME, null);
        return false;
      }
      if (error.message === "No token found")
        return false;
    }
  });

  return { user, isPending, error }
}

export default useMe;