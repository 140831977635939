import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import MainLayout from './components/MainLayout';
// import { Provider } from "mobx-react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import configureStore from "./store/appStore";
import ErrorBoundary from './components/ErrorBoundary';
import { FeedbackProvider } from './components/feedback/Service';
import config from './config';
import SignIn from './components/auth/SignIn';
import SignUpPage from './components/auth/SignUpPage';
import Users from './components/admin/users/Users';
import ResetPassword from './components/auth/ResetPassword';
import Account from './components/Account';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CssBaseline } from '@mui/material';
import Subscribe from './components/Subscribe';

// Create a client
const queryClient = new QueryClient()

// const store = configureStore();

console.log("Razor app, version", config.version);

const palette = {
  mode: 'dark',
  primary: {
    main: "#d9fd26",
    // secondary: "",
  },
};
const theme = createTheme({ palette });

const App = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <FeedbackProvider>
                <MainLayout>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/sign-up" element={<SignUpPage />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/admin/users" element={<Users />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/subscribe" element={<Subscribe />} />
                  </Routes>
                </MainLayout>
              </FeedbackProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
