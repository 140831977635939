import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';

const Plan = ({ data, selected, setSelectedPlanId }) => {
  const handleSelect = () => setSelectedPlanId(data._id);
  return (
    <Card variant={selected ? "outlined" : "elevation"}>
      <CardActionArea onClick={handleSelect}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            {data.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {data.currency}{data.price.toFixed(2)}
          </Typography>
          <Typography variant="body2">
            Monthly data limit: {data.limit}GB
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" disabled={selected} onClick={handleSelect}>{selected ? "Selected" : "Select"}</Button>
      </CardActions>
    </Card>
  );
}

const Plans = ({ data, selectedPlanId, setSelectedPlanId }) => {
  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>Choose a plan</Typography>
      <Grid container spacing={2} alignItems="center" justifyItems="center">
        {data.map(plan => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={plan._id}>
            <Plan data={plan} setSelectedPlanId={setSelectedPlanId} selected={selectedPlanId === plan._id} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Plans;