import React from 'react';
import Box from '@mui/material/Box';
import { ROLES } from '../../../constants';
import DataGrid from '../../../DataGrid';
import { useFeedback } from '../../feedback/Service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser as updateUserFn } from '../../../api';
import SubscriptionsGrid from './SubscriptionsGrid';


const cols = [
  { accessorKey: '_id', header: 'ID', size: 220, hide: true, enableClickToCopy: true },
  { accessorKey: 'email', header: 'Email', size: 250, enableEditing: true, },
  // { accessorKey: 'firstName', header: 'First Name', size: 150, enableEditing: true },
  // { accessorKey: 'lastName', header: 'Last Name', size: 150, enableEditing: true },
  { accessorKey: 'role', header: 'Role', size: 120, enableEditing: true, editVariant: 'select', editSelectOptions: ROLES, },
];

const UsersGrid = ({ data }) => {
  const queryClient = useQueryClient();
  const { mutate: updateUser, isPending } = useMutation({
    mutationFn: updateUserFn,
    onSuccess: () => {
      console.log("onSuccess");
      queryClient.invalidateQueries({ queryKey: ['users'] })
    },
    onError: error => {
      console.error("Update error", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });
  const feedback = useFeedback();

  const handleSaveChanges = async row => {
    try {
      const { _id, email, /* firstName, lastName, */ role } = row;
      const res = await updateUser({ id: _id, email, role })
      console.log("user updated", res);
      feedback.snackbar({ text: "User updated", type: "success" });
    } catch (error) {
      console.warn(error);
      feedback.snackbar({ text: error.message || "Error updating user!", type: "error" });
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        data={data}
        columns={cols}
        onSave={handleSaveChanges}
        isPosting={isPending}
        enableEditing
        renderDetailPanel={({ row }) => (
          <SubscriptionsGrid
            data={row.original.subscriptions}
            sx={{ width: 'calc(100vw - 160px)', height: "auto" }}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            // handleShowDetails={handleShowJobDetails(row.original._id)}
          />)
        }
      />
    </Box>
  );
}

export default UsersGrid;