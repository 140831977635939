// spell-checker: disable
let config = {
  apiUrl: "http://localhost:8083",
  // apiUrl: "https://api.razorvpn.com",
    // apiUrl: "",
  version: "0.2.0",
};

//eslint-disable-next-line
if (process.env.NODE_ENV === "production") {
  config = {
    ...config,
    sentryDsn: "",
    // apiUrl: "http://18.185.152.61:3000",
    apiUrl: "https://api.razorvpn.com",
  }
}

export default config;