import React, { useEffect, useMemo, useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { experimentalStyled as styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import List from '@mui/material/List';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import logo from '../assets/images/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import { AUTH_TOKEN_NAME } from '../constants';
import Box from '@mui/material/Box';
import { mainListItems, /* secondaryListItems */ } from './admin/menuItems';
import useMe from '../hooks/useMe';
import logo from "../assets/images/razor_logo.png"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <MuiLink color="inherit" href="https://razorvpn.com/" target="_blank" rel="noreferrer noopener">
        Razor VPN
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 210;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'openDrawer',
})(({ theme, openDrawer }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(openDrawer && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainLayout = ({ children }) => {
  const { user, isPending: loading, error } = useMe();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const { pathname } = useLocation();
  // const globalLoader = useReactiveVar(loaderVar);

  // useEffect(() => {
  //   const init = async () => {
  //     const data = await getMe();
  //     console.log(data);
  //   }
  //   init();
  // }, [])

  const isAdminInterface = useMemo(() => {
    if (!pathname.includes("/admin") || !user)
      return false;
    return user.role === "admin";
  }, [user, pathname]);

  const handleAccountMenu = event => setAccountAnchorEl(event.currentTarget);

  const handleAccountMenuClose = () => setAccountAnchorEl(null);

  const handleSignOut = () => {
    localStorage.setItem(AUTH_TOKEN_NAME, null);
    window.location.reload();
  }

  const toggleDrawer = () => setOpenDrawer(x => !x);

  useEffect(() => {
    if (!error)
      return;
    if (error.message === "Unauthenticated" || error.message === "No token found")
      return;
    console.error("get user error:", error.message);
  }, [error]);

  useEffect(() => {
    if (!isAdminInterface && openDrawer)
      setOpenDrawer(false);
  }, [isAdminInterface, openDrawer]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  // console.log({ isAdminInterface, user })

  return (
    <Box
      sx={{
        display: 'flex',
        // flexDirection: 'column',
        // minHeight: '100vh',
      }}
    >
      <AppBar position="absolute" color="inherit" openDrawer={openDrawer}>
        <Toolbar sx={{ pr: '24px' }}>
          {isAdminInterface ?
            <>
              <IconButton
                edge="start"
                color="primary"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(openDrawer && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Administration Panel
              </Typography>
            </> :
            <>
              <Link to="/" style={{ flexGrow: 1 }}>
                <img src={logo} alt="Razor VPN" style={{ height: 58, paddingTop: 5, }} />
              </Link>
              {/* <Typography color="primary" variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: "none" }}>Razor VPN</Typography> */}
              {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography> */}
            </>
          }
          {!loading && (user ?
            // <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
            <div>
              <IconButton
                // size="large"
                aria-label="account of current user"
                aria-controls="menu-appBar"
                aria-haspopup="true"
                onClick={handleAccountMenu}
                color="primary"
              >
                <AccountCircle fontSize='large' />
              </IconButton>
              <Menu
                id="menu-appBar"
                anchorEl={accountAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(accountAnchorEl)}
                onClose={handleAccountMenuClose}
              >
                <MenuItem component={Link} to="/account" onClick={handleAccountMenuClose}>Account</MenuItem>
                {(user?.role === "admin" && !isAdminInterface) && <MenuItem component={Link} to="/admin/users" onClick={handleAccountMenuClose}>Administration</MenuItem>}
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </div> :
            <Button color="inherit" component={Link} to="/sign-in">Sign In</Button>)
          }
        </Toolbar>
      </AppBar>
      {/* <LinearProgress variant={globalLoader || "query"} sx={{ visibility: (loading || globalLoader) ? "visible" : "hidden", position: "absolute", top: 64, width: '100vw' }} /> */}
      <LinearProgress variant={"query"} sx={{ visibility: loading ? "visible" : "hidden", position: "absolute", top: 64, width: '100vw' }} />
      {isAdminInterface &&
        <Drawer variant="permanent" open={openDrawer}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          {/* <Divider />
          <List>{secondaryListItems}</List> */}
        </Drawer>
      }
      <Box
        component="main"
        className='main-layout'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          display: "flex",
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
          flexDirection: "column",
          // mt: "74px"
        }}
      >
        <Toolbar />
        <Container maxWidth={isAdminInterface ? false : undefined}>
          {children}
        </Container>
        {!isAdminInterface &&
          <Box
            component="footer"
            sx={{
              py: 3,
              px: 2,
              mt: 'auto',
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[200]
                  : theme.palette.grey[800],
            }}
          >
            <Container maxWidth="sm">
              {/* <Typography variant="body1">
                  My sticky footer can be found here.
                </Typography> */}
              <Copyright />
            </Container>
          </Box>
        }
      </Box>
    </Box>
  );
}

export default MainLayout;