import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import useLoginRedirect from '../../hooks/useLoginRedirect';
import SignUpForm from './SignUpForm';
import useMe from '../../hooks/useMe';
import ConfirmEmail from './ConfirmEmail';

const SignUpPage = () => {
  const { user } = useMe();
  useLoginRedirect(user);

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {user ? <ConfirmEmail userId={user._id} /> : <SignUpForm />}
      </Box>
    </Container>
  );
}

export default SignUpPage;