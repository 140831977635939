import React from 'react';
import UsersGrid from './UsersGrid';
import Paper from '@mui/material/Paper';
import { getUsers } from "../../../api";
import { useQuery } from '@tanstack/react-query';
import { LinearProgress } from '@mui/material';

const Users = () => {
    const { data, isPending } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
  });

  if (isPending)
    return <LinearProgress variant='query' sx={{ width: "100%" }} />;

  if (!data)
    return null;

  return (
    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', mt: 4, mb: 4 }}>
      <UsersGrid data={data} />
    </Paper>
  )
}

export default Users;