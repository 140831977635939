import React from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useFeedback } from '../feedback/Service';
import { AUTH_TOKEN_NAME } from '../../constants';
import Password from './Password';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { register as registerFunc } from '../../api';

export default function SignUpForm() {
  const queryClient = useQueryClient();
  const feedback = useFeedback();
  const { mutate: register, isPending: loading } = useMutation({
    mutationFn: registerFunc,
    onSuccess: token => {
      console.log("onSuccess", token);
      localStorage.setItem(AUTH_TOKEN_NAME, token);
      queryClient.invalidateQueries({ queryKey: ['me'] })
    },
    onError: error => {
      console.error("onError", error);
      feedback.snackbar({ text: error.message, type: "error" });
    }
  });
  // const { refetch: getUser } = useQuery();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = {
      email: data.get('email'),
      password: data.get('password'),
      // firstName: data.get('firstName'),
      // lastName: data.get('lastName'),
      newsletter: !!data.get('allowExtraEmails')
    };
    register({ user });
    // try {
    //   const token = await register({ user });
    //   localStorage.setItem(AUTH_TOKEN_NAME, token);
    //   console.log(token);
    //   // getUser({ fetchPolicy: 'network-only' });
    // } catch (error) {
    //   console.log(error.message);
    //   let errorText = error.message || "Sign up error";
    //   if (error.graphQLErrors?.length > 0 && error.graphQLErrors[0].extensions?.exception?.data?.length > 0)
    //     errorText = error.graphQLErrors[0].extensions?.exception?.data[0].message;
    //   feedback.snackbar({ text: errorText, type: "error" });
    // }
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <Password
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              helperText="Password must be at least 6 characters long"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="primary" value={true} />}
              label="I want to receive inspiration, marketing promotions and updates via email."
              name="allowExtraEmails"
            />
          </Grid>
        </Grid>
        <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} loading={loading}>
          Sign Up
        </LoadingButton>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <MuiLink variant="body2" to="/sign-in" component={Link}>
              Already have an account? Sign in
            </MuiLink>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}