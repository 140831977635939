import React from 'react';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMe from '../hooks/useMe';
import ConfirmEmail from './auth/ConfirmEmail';
import Subscriptions from './Subscriptions';
import { Navigate } from 'react-router-dom';

const Account = () => {
  const { user, isPending } = useMe();
  if (isPending) return null;
  if (!user) return <Navigate to="/sign-in" />;
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      {user.emailConfirmed ?
        <Box sx={{ m: 1 }}>
          {/* <Typography variant="h2">{user.firstName} {user.lastName}</Typography> */}
          {/* <Typography variant="h5">{user.email}</Typography> */}
          {/* <br /> */}
          <Subscriptions />
        </Box>
        : <ConfirmEmail />
      }
    </Box>
  );
}

export default Account;